import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import RichTextEditor, { EditorValue } from 'react-rte';

export type MyProps = { testid:string, checked: boolean; handleClick?: (event: any) => void };

interface AgentDetail {
  id: number;
  full_name: string;
}

export interface RolesListData {
  id: string,
  type: string,
  attributes: {
    name: string;
    description: string;
    role_type?: string;
    agent_count?: number
    agent_details: Array<AgentDetail>;
  }
}

interface FormInitalValue {
  name: string;
  description: EditorValue;
  role_type: string;
  permissions: string;
}

export interface TicketPermission {
  viewTicketsPermission: { ticketReplyPermission: boolean };
  editNotesPermission: { editEveryoneNotesPermission: boolean, editOwnNotesPermission: boolean };
  deleteConversationPermission: boolean;
  editTicketPropertiesPermission: { cancelApprovalsPermission: boolean };
  editTicketTaskPermission: boolean;
  deleteTicketPermission: boolean
}

export interface ProblemPermission {
  viewProblemsPermission: { createProblemsPermission: boolean, editProblemsPermission: boolean,
  deleteProblemsPermission: boolean, createEditTaskPermission: boolean }
}

export interface ChangesPermission {
  viewChagesPermission: { createChangesPermission: boolean, editChangesPermission: boolean,
    deleteChangesPermission: boolean, createEditTaskChangesPermission: boolean
  }
}

export interface ReleasePermission {
  viewReleasesPermission: { createReleasesPermission: boolean, editReleasesPermission: boolean,
    deleteReleasesPermission: boolean, createEditTaskReleasesPermission: boolean
  }
}

export interface InventoryPermission {
  viewAssetsPermission: { createEditAssetPermission: boolean, deleteAssetPermission: boolean };
}

export interface SolutionPermission {
  viewSolutionPermission: { publishSolutionPermission: boolean, deleteSolutionPermission: boolean };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtSavedValue: string,
  isLoading: boolean,
  initialValues: FormInitalValue;
  showModal: boolean;
  isInfoModalOpen: boolean;
  agentPermissions: string[];
  agentTicketsPermissions: TicketPermission;
  agentProblemsPermissions: ProblemPermission;
  agentChangesPermissions: ChangesPermission;
  agentReleasesPermissions: ReleasePermission;
  agentInventoryPermissions: InventoryPermission;
  agentSolutionsPermissions: SolutionPermission;
  selectedPermission: string;
  openListIndex: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateAgentRoleController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtSavedValue: "",
      isLoading: false,
      initialValues: {
        name: "",
        role_type: "",
        description: RichTextEditor.createEmptyValue(),
        permissions: ""
      },
      showModal: false,
      isInfoModalOpen: false,
      agentPermissions: this.permissionsList,
      selectedPermission: this.permissionsList[0],
      agentTicketsPermissions: { viewTicketsPermission: { ticketReplyPermission: false },
        editNotesPermission: { editEveryoneNotesPermission: false, editOwnNotesPermission: false },
        deleteConversationPermission: false,
        editTicketPropertiesPermission: { cancelApprovalsPermission: false },
        editTicketTaskPermission: false,
        deleteTicketPermission: false },
      agentProblemsPermissions: { viewProblemsPermission: { createProblemsPermission: false, editProblemsPermission: false,
        deleteProblemsPermission: false, createEditTaskPermission: false }},
      agentChangesPermissions:{ viewChagesPermission: { createChangesPermission: false, editChangesPermission: false,
        deleteChangesPermission: false, createEditTaskChangesPermission: false } },
      agentReleasesPermissions: { viewReleasesPermission: { createReleasesPermission: false, editReleasesPermission: false,
        deleteReleasesPermission: false, createEditTaskReleasesPermission: false }},
      agentInventoryPermissions: { viewAssetsPermission: { createEditAssetPermission: false, deleteAssetPermission: false }},
      agentSolutionsPermissions: { viewSolutionPermission: { publishSolutionPermission: false, deleteSolutionPermission: false } },
      openListIndex: -1
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  permissionsList = [configJSON.ticketsPermissions, 
    configJSON.problemPermissions, configJSON.changesPermissions, configJSON.releasesPermissions,
    configJSON.inventoryPermissions, configJSON.solutionsPermissions];

  handleSubmit = () => {

  }

  handleNavigation = (path:string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }

  handlePermissionChange = (value: boolean) => {

  }

  handlePermissions = (permission: string) => {
    this.setState({ selectedPermission: permission });
  }
  // Customizable Area End
}
