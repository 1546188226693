import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import RichTextEditor, { EditorValue } from 'react-rte';
import { FormikHelpers } from "formik";
import toast from "react-hot-toast";

interface AgentDetail {
  id: number;
  full_name: string;
}

export interface RolesListData {
  id: string,
  type: string,
  attributes: {
    name: string;
    description: string;
    role_type?: string;
    agent_count?: number
    agent_details: Array<AgentDetail>;
  }
}

interface FormInitalValue {
  name: string;
  description: string;
  role_type: string;
  permissions: PermissionList;
}

export interface PermissionList {
  permissionOne: boolean;
  permissionTwo: boolean;
  permissionThree: boolean;
  permissionFour: boolean;
  permissionFive: boolean;
  permissionSix: boolean;
  permissionSeven: boolean;
  permissionEight: boolean;
  permissionNine: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtSavedValue: string,
  isLoading: boolean,
  initialValues: FormInitalValue;
  showModal: boolean;
  isInfoModalOpen: boolean;
  adminPermissions: PermissionList;
  openListIndex: number;
  token: string;
  editorValue: EditorValue;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateAdminRoleController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAdminRoleApiCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtSavedValue: "",
      token: localStorage.getItem("authToken") || "",
      isLoading: false,
      
      initialValues: {
        name: "",
        role_type: "admin_roles",
        description: "",
        permissions: this.permissionsList
      },
      showModal: false,
      isInfoModalOpen: false,
      adminPermissions: this.permissionsList,
      openListIndex: -1,
      editorValue: RichTextEditor.createEmptyValue()
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )

    switch (apiRequestId) {
      case this.createAdminRoleApiCallId:
        this.handleCreateAdminRoleResponse(responseJson)
        break;
      default:
    }
    // Customizable Area End
  }

  // Customizable Area Start
  permissionsList = { permissionOne: false , 
    permissionTwo: false, permissionThree: false, 
    permissionFour: false,
    permissionFive: false, permissionSix: false, 
    permissionSeven: false,
    permissionEight: false,  permissionNine: false};

  handleCreateAdminRoleResponse(responseJson: any) {
    if (responseJson.data) {
      toast.success('Admin role added successfully.')
      this.props.navigation.navigate("Rolesandpermissions");
    } else if (responseJson.errors) {
      toast.error(responseJson.errors[0])
    }
  }

  handleDescription = (value: EditorValue, setFieldValue: (field: string, value: any) => void) => {
    const htmlContent = value.toString('html');
    const isEmptyContent = htmlContent === '<p><br></p>' || !htmlContent.trim();
    this.setState({ editorValue: value });
    setFieldValue('description', isEmptyContent ? '' : htmlContent);
  }

  handlePermissions = (permission: string, setFieldValue: (field: string, value: any) => void) => {
    const checked = !this.state.adminPermissions[permission as keyof PermissionList];
    console.log("checked", checked);
    setFieldValue(`permissions.${permission}`, checked);
    this.setState((prevState) => 
      ({adminPermissions: 
        {...prevState.adminPermissions, [permission]: checked }
      }));
  }

  handleSubmit = (values: FormInitalValue, formikHelpers: FormikHelpers<FormInitalValue>): void => {
    console.log("values---", values, "....formikHelpers", formikHelpers);
    const header = {
        'Content-Type': configJSON.fieldsApiContentType,
        token: this.state.token
      };
      const httpBody = {
        "name": values.name,
        "description": values.description,
        "role_type": values.role_type,
        "permissions_attributes": [
          {
            "option_name": "admin can",
            "fields": {
              "perform_admin_operational_admin_actions": true,
              "manage_team_dashboards": values.permissions.permissionOne,
              "manage_agents": values.permissions.permissionTwo,
              "manage_requester": values.permissions.permissionThree,
              "manage_ticket_templates": values.permissions.permissionFour,
              "manage_change_templates ": values.permissions.permissionFive,
              "manage_departments": values.permissions.permissionSix,
              "create_and_edit_service_request_items": values.permissions.permissionSeven,
              "manage_sla_and_ola_policies": values.permissions.permissionEight,
              "play_god_mode": values.permissions.permissionNine
            }
          } 
        ]
      }
    
    
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.createAdminRoleApiCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
       `${configJSON.createAdminRoleEndPoint}`
      );
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.adminCreateApiMethod
      );
  
     
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNavigation = (path:string)=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }

  // Customizable Area End
}
