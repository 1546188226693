import React from "react";
// Customizable Area Start
import {
  Button,
  Input,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Typography,
  Box,
  styled,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  createTheme,
  ThemeProvider,
  Dialog,
} from "@material-ui/core";
import {
  MoreVert,
  Search,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Close
} from "@material-ui/icons";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import DepartmentFieldsController , { DynamicField, Props, configJSON } from "./DepartmentFieldsController";
import CommonModal from "./CommonModal.web";
import { infoIcon, infoIconOutlined } from "./assets";
import { Toaster } from 'react-hot-toast';


const CustomCard = styled(Box)({
  padding: '12px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #E6DEED',
  borderRadius: '8px'
});

const CustomText = styled(Typography)({
  color: '#33354D',
  fontFaily: 'Manrope',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
})

const FieldRow = styled(Box)({
  width: '100%',
  marginBottom: '0.5rem',
});

const DialogFieldRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginBottom: '0.5rem',
  gap: '1rem'
});

const themeDept = createTheme({
  overrides: {
    MuiRadio: {
      root: {
        color: '#8760A9',
      },
      colorSecondary: {
        '&$checked': {
          color: '#8760A9',
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: '#8760A9',
      },
      colorSecondary: {
        '&$checked': {
          color: '#8760A9',
        },
      },
    }
  },
})


// Customizable Area End

export default class DepartmentFields extends DepartmentFieldsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDepartmentFields() {
    const { departmentFields } = this.state;
    if (departmentFields.length === 0) {
      return configJSON.noDataMessage;
    }

    return departmentFields.map((field : any, index: any) => {
      if (!field.attributes.status) {
        return null;
      }

      return (
        <CustomCard key={`key-${index}`}>
          <FieldRow><CustomText>{field.attributes.title}</CustomText></FieldRow>
        </CustomCard>
      );
    });
  }


  // Customizable Area End

  render() {
     // Customizable Area Start
     // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
        <Toaster />
        <ThemeProvider theme={themeDept}>
          <Box style={styles.containerDept}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span style={styles.navigationDept}>
                Settings {">"} User management {">"} Department Fields
              </span>
              <Box style={styles.moreIconMainStyleDept}>
                <span style={{ height: '36px', width: '36px' }}>
                  <MoreVert style={styles.moreIconStyleDept} />
                </span>
              </Box>
            </Box>

            <Box style={{ marginTop: "60px", marginBottom: "50px", }}>
              <Box style={{ gap: "5px", display: "flex", }}>
                <button style={styles.arrowDept}>
                  <KeyboardArrowLeft style={styles.arrow_iconDept} />
                </button>
                <button style={styles.arrowDept}>
                  <KeyboardArrowRight style={styles.arrow_iconDept} />
                </button>
              </Box>
              <Box
                style={{ alignItems: "center", gap: "5px", display: "flex", }}>
                <h1 style={styles.headingDept}>Department Fields</h1>
                <img
                  style={styles.infoIconsStyleDept}
                  src={this.state.isInfoModalOpen ? infoIcon : infoIconOutlined}
                  aria-describedby="infoIcon"
                  onClick={(e: React.MouseEvent<HTMLElement>) => this.handleInfoIconClick(e)} />
              </Box>
            </Box>

            <Box style={{ position: "relative", marginBottom: "32px" }}>
              <Search style={styles.search_iconDept} />
              <Input
                disableUnderline={true}
                onChange={(e) => this.setInputValue(e.target.value)}
                style={styles.inputDept}
                placeholder={"Search..."}
              />
            </Box>
            <Box style={{ display: "flex", gap: "20px" }}>
              <Box flex={1}>

                <Box style={styles.main_content_cardDept}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "37px",
                    }}
                  >
                    <Box style={styles.cardHeadDept} >
                      <span style={styles.card_headingDept}>
                      Department Fields  
                      </span>
                      <span style={styles.countDept}>
                        ({this.returAgentFieldsLength()})
                      </span>
                    </Box>
                  </Box>
                  <Box style={styles.card_main_divDept}>
                  {this.renderDepartmentFields()}  
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "flex-end", marginTop: "42px" }}>
                    <Button style={styles.edit_buttonDept} onClick={() => this.handleEditToggle('departmentFields')} data-test-id='editDeptFields'>
                      {configJSON.editLabel}
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box style={styles.accordiansDept}>
                <List component="nav">
                  {this.navigationList.map((item: any, index: number) => {
                    if (item.list.length) {
                      return (
                        <Box key={`key-${item.title}-${index}`}>
                          <ListItem
                            button
                            onClick={() =>
                              this.openAccordian(index)
                            }
                            style={{
                              ...styles.navigation_itemDept,
                              ...(this.isPathExist(item.list) &&
                                styles.navigation_menu_bgDept),
                            }}
                            data-test-id='openAccordian'
                          >
                            <ListItemText
                              style={{
                                ...styles.navigation_linkDept,
                                ...(this.isPathExist(item.list) &&
                                  styles.navigation_menu_colorDept)
                              }}
                              primary={item.title}
                              disableTypography={true}
                            />
                          </ListItem>
                          <Collapse
                            in={
                              this.state.openListIndex ===
                              index
                            }
                            timeout="auto"
                            unmountOnExit
                            style={{ marginTop: '10px' }}
                          >
                            {item.list.map((link: any, index: number) => (
                              <List
                                key={`key-${link.title}-${index}`}
                                disablePadding
                              >
                                <ListItem
                                  button
                                  style={{
                                    ...styles.navigation_item_nestedDept,
                                    ...(this.pathname ===
                                      link.href &&
                                      styles.navigation_item_activeDept),
                                  }}
                                  component="a"
                                  href={link.href}
                                >
                                  <ListItemText
                                    style={{
                                      ...styles.navigation_linkDept,
                                      ...(this.pathname ===
                                        link.href &&
                                        styles.navigation_link_activeDept),

                                    }}
                                    disableTypography={
                                      true
                                    }
                                    primary={
                                      link.title
                                    }
                                  />
                                </ListItem>
                              </List>
                            ))}
                          </Collapse>
                        </Box>
                      );
                    }

                    return (
                      <ListItem
                        style={styles.navigation_itemDept}
                        key={item.title}
                        button
                      >
                        <ListItemText
                          style={styles.navigation_linkDept}
                          primary={item.title}
                          disableTypography={true}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Box>
          </Box>
          <CommonModal
            isOpen={this.state.isInfoModalOpen}
            onClose={() => this.handelInfoModal(false)}
            title={configJSON.DepartmentFieldModalTitle}
            anchorEl={this.state.anchorEl}
            id="infoIcon"
            data={[
              configJSON.DepartmentFieldModal1,
              configJSON.DepartmentFieldModal2
            ]}
          />
          <Dialog
            open={this.state.openEditModal}
            fullWidth={true}
            maxWidth='md'
            PaperProps={{
              style: { padding: '20px' }
            }}
          >
            <Box style={styles.modal_headerDept}>
              <Typography style={styles.modal_headingDept}>Edit department fields</Typography>
              <Close style={styles.closeIconDept} onClick={() => this.handleCloseEditModal(false)} data-test-id='close-modal-icon' />
            </Box>
            <Box style={styles.card_main_divDept}>
              {this.state.fieldsData.map((field: DynamicField, index: number) => (
                <CustomCard key={`card-${index}`}>
                  <DialogFieldRow>
                    {field.attributes.mandatory === false && <Checkbox
                      checked={field?.attributes?.status}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleRequiredChange(index, e.target.checked)}
                    />}
                    <CustomText>{field.attributes.title}</CustomText>
                  </DialogFieldRow>
                  {(field.attributes.status === true && field.attributes.mandatory === false) &&
                    <RadioGroup
                      row
                      value={field.attributes.mandatory ? "yes" : "no"}
                      onChange={(e) => this.handleMandatoryChange(index, e.target.value)}
                      style={styles.radioButtonsStyleDept}
                    >
                      <Typography>{configJSON.radioQuestion}</Typography>
                      <Box>
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                      </Box>
                    </RadioGroup>
                  }
                </CustomCard>
              ))}
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "42px",
                paddingBottom: '1rem'
              }}
            >
              <Button style={styles.cancel_buttonDept} onClick={() => this.handleCancelButton()}>{configJSON.cancelBTN}</Button>
              <Button style={{...styles.create_buttonDept, textTransform: 'initial'}} onClick={() => this.handleSaveData()}>{configJSON.saveBTN}</Button>
            </Box>
          </Dialog>
        </ThemeProvider>

      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
    containerDept: {
        height: "100%",
        margin: "15px 15px 15px 300px",
        paddingBottom: "25px",
        fontFamily: "Manrope",
      } as const,
      headingDept: {
        fontSize: "30px",
        lineHeight: "40px",
        marginTop: "10px",
        color: "#33354D",
        fontWeight: "bold",
        marginBottom: '0'
      } as const,
      arrowDept: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "28px",
        height: "28px",
        borderRadius: "100px",
        border: "1px solid #E6DEED",
        backgroundColor: "white",
        cursor: "pointer",
      } as const,
      arrow_iconDept: {
        width: "20px",
        height: "20px",
        color: "#33354D",
      } as const,
      settingDept: {
        display: "flex",
        alignItems: "center",
        justifyItems: "center",
        width: "36px",
        height: "36px",
        borderRadius: "4px",
        backgroundColor: "#E6DEED",
      } as const,
      settings_iconDept: {
        width: "24px",
        height: "24px",
        color: "black",
      } as const,
      table_headingDept: {
        color: "#33354D",
        fontFamily: 'Manrope',
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        marginBottom: '30px'
      } as const,
      card_headingDept: {
        color: "#33354D",
        fontFamily: 'Manrope',
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
      } as const,
      countDept: {
        fontSize: "14px",
        lineHeight: "19px",
        fontWeight: "bold",
      } as const,
      main_content_cardDept: {
        flex: "1",
        borderRadius: "8px",
        padding: "16px",
        alignSelf: "flex-start",
        boxShadow: '0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)',
        marginBottom: '1rem'
      } as const,
      edit_buttonDept: {
        padding: "10px 16px",
        backgroundColor: "#E6DEED",
        color: "#33354D",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: "bolder",
        lineHeight: "16px",
        letterSpacing: "0em",
        borderRadius: "4px",
        textTransform: "initial",
      } as const,
      create_buttonDept: {
        color: '#FFF',
        fontFamily: 'Manrope',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        borderRadius: '4px',
        background:'#8760A9',
      },
      cancel_buttonDept: {
        fontSize: "16px",
        padding: "10px 16px",
        backgroundColor: "transparent",
        fontFamily: "Manrope",
        color: "#8760A9",
        fontWeight: 700,
        letterSpacing: "0em",
        lineHeight: "16px",
        textTransform: "initial",
        borderRadius: "4px",
      } as const,
      accordiansDept: {
        flexBasis: "30%",
        boxShadow: "0px 4px 8px 0px #8760A921",
        borderRadius: "8px",
        padding: "4px",
      } as const,
      navigationDept: {
        fontFamily: "Manrope",
        fontSize: "14px",
        lineHeight: "19px",
      } as const,
      inputDept: {
        fontFamily: "Manrope",
        width: "100%",
        height: "40px",
        border: "1px solid #e6deed",
        padding: "10px 10px 10px 40px",
        fontSize: "16px",
        borderRadius: "10px",
      } as const,
      search_iconDept: {
        width: "24px",
        height: "24px",
        color: "#33354D",
        position: "absolute",
        top: "50%",
        left: "10px",
        transform: "translateY(-50%)",
      } as const,
      navigation_itemDept: {
        padding: "9px, 20px, 9px, 16px",
        borderRadius: "4px",
      } as const,
      navigation_item_nestedDept: {
        padding: "9px 20px 9px 30px",
        borderRadius: "4px",
      } as const,
      navigation_item_activeDept: {
        backgroundColor: "#8760A9",
        color: "white",
      } as const,
      navigation_linkDept: {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: "22px",
        letterSpacing: "0em",
        color: "#33354D",
      } as const,
      navigation_link_activeDept: {
        color: "white",
      } as const,
      form_main_BoxDept: {
        padding: '1rem',
      } as const,
      modal_button_main_BoxDept: {
        display: 'flex',
        gap: '1rem',
        float: 'right',
      } as const,
      cardHeadDept: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
      },
      card_main_divDept: {
        display: 'flex',
        gap: '8px',
        flexDirection: 'column'
      } as const,
    radioButtonsStyleDept: {
    marginLeft: '15px',
    flexDirection: 'column'
  } as const,
  infoIconsStyleDept: {
    color: "#8760A9",
    cursor: 'pointer',
    marginLeft: '10px'
  } as const,
  modal_headerDept: {
    display: 'flex',
    justifyContent: "space-between",
    margin: '1rem 0 2rem'
  } as const,
  closeIconDept: {
    cursor: 'pointer',
  } as const,
  navigation_menu_bgDept: {
    backgroundColor: "#8760A9",
  } as const,
  navigation_menu_colorDept: {
    color: "white",
  } as const,
  moreIconMainStyleDept: {
    background: '#e6deed',
    borderRadius: '5px'
  } as const,
  moreIconStyleDept: {
    width: '24px',
    height: '24px',
    color: 'black',
    padding: '5px'
  } as const,
  modal_headingDept:  {
    fontFamily : 'Manrope',
    fontSize: '20px',
    fontWeight: 700
  }
};
// Customizable Area End

// Customizable Area Start
// Customizable Area End