import React from "react";
// Customizable Area Start
import CreateAdminRoleController, { PermissionList, Props, configJSON } from "./CreateAdminRoleController";
import { Box, Button, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Field, Form, Formik } from "formik";
import RichTextEditor from 'react-rte';
import * as Yup from 'yup';
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import Loader from "../../../components/src/Loader.web";
import { MoreVert } from "@material-ui/icons";
import EditIcon from '@material-ui/icons/Edit';
import { CustomRadio } from "./CreateAgentRole.web";
import { Toaster } from "react-hot-toast";

const validationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .required('Name field is required')
    .min(4, 'Please enter at least 4 characters'),
  description: Yup.string()
  .required('Description is required')
  .test('is-not-empty', 'Description is required', (value) => {
    return value && value.trim() !== '';
  })
});

const PermissionListItemText = styled(ListItemText)({
  "&.MuiListItemText-root .MuiTypography-body1": {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Manrope",
    color: "#33354D",
  }
});

// Customizable Area End

export default class CreateAdminRole extends CreateAdminRoleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

  }

  // Customizable Area Start
  // Customizable Area End

  render () {
    return (
      // Customizable Area Start
      <React.Fragment>
        <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
        <Loader loading={this.state.isLoading} />
        <Toaster />
        <Box style={styles.container}>
          <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span style={styles.navigation}>
                Settings {">"} User management {">"} Roles
              </span>
              <Box style={{ background: '#e6deed', borderRadius: '5px' }}>
                <span style={{ width: '36px', height: '36px', }}>
                  <MoreVert style={{ width: '24px', height: '24px', color: 'black', padding: '5px' }} />
                </span>
              </Box>
          </Box>
          <Box
            style={{
              marginTop: "60px",
              marginBottom: "50px",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <h1 style={styles.heading} data-test-id="roleTestID">{configJSON.newAdminRole}</h1>
              <EditIcon fontSize="small" data-test-id="edit" onClick={() =>{}} />
            </Box>
          </Box>
          <Box style={styles.formBox}>
            <Formik
                  initialValues={this.state.initialValues}
                  validationSchema={validationSchema}
                  onSubmit={this.handleSubmit}
                  data-test-id="formikClickEvent"
                >
                  {({ errors, touched, values, setFieldValue }) => (
                    <Form translate={undefined} style={styles.form_main_Box}>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <InputLabel style={styles.label} htmlFor="name">
                            {configJSON.roleNameLabel + " "}<span style={styles.role_span}>*</span>
                          </InputLabel>
                          <Field
                            type="text"
                            data-test-id="role-name"
                            name="name"
                            id="name"
                            placeholder="Name"
                            as={TextField}
                            fullWidth
                            variant="outlined"
                            error={touched.name && !!errors.name}
                            helperText={touched.name && errors.name}
                          />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 15 }}>
                          <InputLabel style={styles.label} htmlFor="description">
                            {configJSON.description + " "}<span style={styles.role_span}>*</span>
                          </InputLabel>
                          <RichTextEditor
                            data-test-id="text-editor"
                            rootStyle={{ width:"50%", minHeight: "150px", border: (touched.description && errors.description) && '1px solid #DC2626' }}
                            value={this.state.editorValue}
                            onChange={(value) => 
                              {
                                this.handleDescription(value, setFieldValue);
                              }}
                            placeholder="Enter description"
                          />
                          {touched.description && errors.description && (
                            <p style={styles.error}>{errors.description}</p>
                          )}
                        </Grid>
                        <Grid item xs={12} style={{ border: "1px solid #E6DEED", marginTop: 15 }}>
                          <Typography style={styles.permissionHeading}>Admin can</Typography>
                          <Box display="flex" alignItems="center">
                            <CustomRadio checked testid="perform-action" /><Typography style={styles.permissionItem}>{configJSON.permissionHead}</Typography>
                          </Box>
                          <List>
                            {Object.keys(this.state.adminPermissions).map((permission: string) =>
                             <ListItem key={permission} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                <ListItemIcon>
                                    <CustomRadio checked={values.permissions[permission as keyof PermissionList]} 
                                    testid="admin-permissions" handleClick={() => this.handlePermissions(permission, setFieldValue)} />
                                </ListItemIcon>
                                <PermissionListItemText style={styles.permission}>
                                  {configJSON[permission]}
                                </PermissionListItemText>
                            </ListItem>)}
                          </List>
                        </Grid>
                      </Grid>
                      <Box mt={'30px'} style={styles.buttonBox}>
                        <Button data-test-id="cancelBtn" type="reset" style={styles.cancelButton} onClick={() => this.handleNavigation("Rolesandpermissions")}>{configJSON.cancelBTN}</Button>
                        <Button type="submit" data-test-id="saveBtn" style={styles.addButton} >{configJSON.saveBTN}</Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
          </Box>
        </Box>
      </React.Fragment>
      // Customizable Area End
    )
  }
}

// Customizable Area Start
const styles: Record<string, React.CSSProperties> = {
  container: {
    height: "100%",
    margin: "15px 15px 15px 300px",
    paddingBottom: "125px",
    fontFamily: "Manrope",
  } as const,

  error: {
    fontFamily: "Manrope",
    fontWeight: 400,
    fontSize: 12,
    color: '#DC2626',
    margin: 0,
    lineHeight: "18px"
  },

  label: {
    marginBottom: 5,
    fontSize: 12,
    fontWeight: 500,
    color: "#3D405C",
    fontFamily: "Manrope"
  },
  heading: {
    color: "#33354D",
    fontSize: "30px",
    margin: "10px 0 0",
    lineHeight: "40px",
    fontWeight: "bold",
  } as const,

  arrow_icon: {
    width: "20px",
    color: "#33354D",
    height: "20px",
  } as const,

  formBox: {
    padding: "24px",
    boxShadow: "rgba(135, 96, 169, 0.16) 0px 8px 32px 0px, rgba(135, 96, 169, 0.13) 0px 4px 8px 0px",
    borderRadius: "8px",
    gap: "24px"
  },

  permissionHeading: {
    marginTop: 10, 
    marginLeft: 15,
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "Manrope",
    lineHeight: "22px"
  },
  permissionItem: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Manrope",
    color: "#33354D"
  },
  role_span: {
    color: "#F22A2A"
  },

  buttonBox: {
    display: "flex",
    justifyContent: "flex-end"
  },

  cancelButton: {
    backgroundColor: "transparent",
    color: "#8760A9",
    padding: "10px 16px",
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "16px",
    borderRadius: "4px",
    letterSpacing: "0em",
    textTransform: "initial",
  } as const,

  addButton: {
    padding: "10px 16px",
    color: "#FFF",
    backgroundColor: "#8760A9",
    fontSize: "16px",
    fontFamily: "Manrope",
    fontWeight: 700,
    letterSpacing: "0em",
    lineHeight: "16px",
    borderRadius: "4px",
    textTransform: "initial",
  } as const,

}
// Customizable Area End

