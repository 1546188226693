import React from "react";
// Customizable Area Start
import CreateAgentRoleController, { ChangesPermission, InventoryPermission, MyProps, ProblemPermission, Props, ReleasePermission, SolutionPermission, TicketPermission, configJSON } from "./CreateAgentRoleController";
import { Box, Button, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, Radio, TextField, Typography } from "@material-ui/core";
import { styled, makeStyles } from "@material-ui/core/styles";
import { Field, Form, Formik } from "formik";
import RichTextEditor from 'react-rte';
import * as Yup from 'yup';
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import Loader from "../../../components/src/Loader.web";
import { MoreVert } from "@material-ui/icons";
import EditIcon from '@material-ui/icons/Edit';

const validationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .required('This field is required')
    .min(4, 'Please enter at least 4 characters'),
  description: Yup.mixed().test(
    'editor-content',
    'Content is required',
    (value) => value && value.toString('markdown').trim().length === 0
  ),
  permissions: Yup.string().trim().required("This field is required")
});

const PermissionListItemText = styled(ListItemText)({
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "Manrope",
    color: "#33354D",
});

const PermissionListItem = styled(ListItem)({
  borderRadius: 8,
  width: 245
}) 

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 20,
    height: 20,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#FFFFFF',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#FFFFFF',
    },
  },
  checkedIcon: {
    backgroundColor: '#8760A9',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#8760A9',
    },
  },
});

export const CustomRadio = (props: MyProps) => {

  const { testid } = props;
  const classes = useStyles();
  return (
      <Radio
      data-test-id={testid}
      className={classes.root}
      disableRipple
      color="default"
      onClick={props.handleClick}
      checkedIcon={<span className={`${classes.icon} ${classes.checkedIcon}`} />}
      icon={<span className={classes.icon} />}
      {...props}
      />
  );
  
}

// Customizable Area End

export default class CreateAgentRole extends CreateAgentRoleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

  }

  // Customizable Area Start
  renderTicketPermissions(): React.ReactElement {
      const { agentTicketsPermissions } = this.state;
      return(
        <List disablePadding>
          {Object.keys(agentTicketsPermissions).map((permissionKey: string) => {
          return(
            <ListItem key={permissionKey} style={styles.permissionsList} disableGutters>
              <Box display="flex" alignItems="center">
                <ListItemIcon><CustomRadio checked testid="ticket" handleClick={() => this.handlePermissionChange(true)} /> </ListItemIcon>
                <ListItemText><Typography style={styles.permissionItem}>{configJSON[permissionKey]}</Typography></ListItemText>
              </Box>
              {Object.keys(agentTicketsPermissions[permissionKey as keyof(TicketPermission)]).length > 0 && 
              <List disablePadding>
                {Object.keys(agentTicketsPermissions[permissionKey as keyof(TicketPermission)]).map((ticketPermission: string) => 
                <ListItem key={ticketPermission} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <ListItemIcon><CustomRadio checked={false} testid="ticket-permission" handleClick={() => this.handlePermissionChange(false) }/></ListItemIcon>
                  <ListItemText><Typography style={styles.permissionItem}>{configJSON[ticketPermission]}</Typography></ListItemText>
                </ListItem>)}
              </List>}
          </ListItem>)}
        )}
      </List>
      )
  }
  renderProblemPermissions(): React.ReactElement {
    const { agentProblemsPermissions } = this.state;
      return(
        <List disablePadding>
          {Object.keys(agentProblemsPermissions).map((permissionKey: string) => {
          return(
            <ListItem key={permissionKey} style={styles.permissionsList} disableGutters>
              <Box display="flex" alignItems="center">
                <ListItemIcon><CustomRadio checked testid="problem" handleClick={() => this.handlePermissionChange(true)} /> </ListItemIcon>
                <ListItemText><Typography style={styles.permissionItem}>{configJSON[permissionKey]}</Typography></ListItemText>
              </Box>
              {Object.keys(agentProblemsPermissions[permissionKey as keyof(ProblemPermission)]).length > 0 && 
              <List disablePadding>
                {Object.keys(agentProblemsPermissions[permissionKey as keyof(ProblemPermission)]).map((problemPermission: string) => 
                <ListItem key={problemPermission} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <ListItemIcon><CustomRadio checked={false} testid="problem-permission" handleClick={() => this.handlePermissionChange(false)} /></ListItemIcon>
                  <ListItemText><Typography style={styles.permissionItem}>{configJSON[problemPermission]}</Typography></ListItemText>
                </ListItem>)}
              </List>}
          </ListItem>)}
        )}
      </List>
    )
  }
  renderChangesPermissions(): React.ReactElement {
    const { agentChangesPermissions } = this.state;
      return(
        <List disablePadding>
          {Object.keys(agentChangesPermissions).map((permissionKey: string) => {
          return(
            <ListItem key={permissionKey} style={styles.permissionsList} disableGutters>
              <Box display="flex" alignItems="center">
                <ListItemIcon><CustomRadio checked testid="changes" handleClick={() => this.handlePermissionChange(true) }/> </ListItemIcon>
                <ListItemText><Typography style={styles.permissionItem}>{configJSON[permissionKey]}</Typography></ListItemText>
              </Box>
              {Object.keys(agentChangesPermissions[permissionKey as keyof(ChangesPermission)]).length > 0 && 
              <List disablePadding>
                {Object.keys(agentChangesPermissions[permissionKey as keyof(ChangesPermission)]).map((changesPermission: string) => 
                <ListItem key={changesPermission} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <ListItemIcon><CustomRadio checked={false} testid="changes-permission" handleClick={() => this.handlePermissionChange(false)} /></ListItemIcon>
                  <ListItemText><Typography style={styles.permissionItem}>{configJSON[changesPermission]}</Typography></ListItemText>
                </ListItem>)}
              </List>}
          </ListItem>)}
        )}
      </List>
    )
  }
  renderReleasePermissions(): React.ReactElement {
    const { agentReleasesPermissions } = this.state;
      return(
        <List disablePadding>
          {Object.keys(agentReleasesPermissions).map((permissionKey: string) => {
          return(
            <ListItem key={permissionKey} style={styles.permissionsList} disableGutters>
              <Box display="flex" alignItems="center">
                <ListItemIcon><CustomRadio checked testid="release" handleClick={() => this.handlePermissionChange(true)}/> </ListItemIcon>
                <ListItemText><Typography style={styles.permissionItem}>{configJSON[permissionKey]}</Typography></ListItemText>
              </Box>
              {Object.keys(agentReleasesPermissions[permissionKey as keyof(ReleasePermission)]).length > 0 && 
              <List disablePadding>
                {Object.keys(agentReleasesPermissions[permissionKey as keyof(ReleasePermission)]).map((releasePermission: string) => 
                <ListItem key={releasePermission} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <ListItemIcon><CustomRadio checked={false} testid="release-permission"  handleClick={() => this.handlePermissionChange(false)} /></ListItemIcon>
                  <ListItemText><Typography style={styles.permissionItem}>{configJSON[releasePermission]}</Typography></ListItemText>
                </ListItem>)}
              </List>}
          </ListItem>)}
        )}
      </List>
    )
  }
  renderInventoryPermission(): React.ReactElement {
    const { agentInventoryPermissions } = this.state;
      return(
        <List disablePadding>
          {Object.keys(agentInventoryPermissions).map((permissionKey: string) => {
          return(
            <ListItem key={permissionKey} style={styles.permissionsList} disableGutters>
              <Box display="flex" alignItems="center">
                <ListItemIcon><CustomRadio checked testid="inventory" handleClick={() => this.handlePermissionChange(true) }/> </ListItemIcon>
                <ListItemText><Typography style={styles.permissionItem}>{configJSON[permissionKey]}</Typography></ListItemText>
              </Box>
              {Object.keys(agentInventoryPermissions[permissionKey as keyof(InventoryPermission)]).length > 0 && 
              <List disablePadding>
                {Object.keys(agentInventoryPermissions[permissionKey as keyof(InventoryPermission)]).map((inventoryPermission: string) => 
                <ListItem key={inventoryPermission} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <ListItemIcon><CustomRadio checked={false} testid="inventory-permission" handleClick={() => this.handlePermissionChange(false)}/></ListItemIcon>
                  <ListItemText><Typography style={styles.permissionItem}>{configJSON[inventoryPermission]}</Typography></ListItemText>
                </ListItem>)}
              </List>}
          </ListItem>)}
        )}
      </List>
    )
  }
  renderSolutionPermission(): React.ReactElement {
    const { agentSolutionsPermissions } = this.state;
      return(
        <List disablePadding>
          {Object.keys(agentSolutionsPermissions).map((permissionKey: string) => {
          return(
            <ListItem key={permissionKey} style={styles.permissionsList} disableGutters>
              <Box display="flex" alignItems="center">
                <ListItemIcon><CustomRadio checked testid="solution" handleClick={() => this.handlePermissionChange(true)}/> </ListItemIcon>
                <ListItemText><Typography style={styles.permissionItem}>{configJSON[permissionKey]}</Typography></ListItemText>
              </Box>
              {Object.keys(agentSolutionsPermissions[permissionKey as keyof(SolutionPermission)]).length > 0 && 
              <List disablePadding>
                {Object.keys(agentSolutionsPermissions[permissionKey as keyof(SolutionPermission)]).map((solutionPermission: string) => 
                <ListItem key={solutionPermission} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <ListItemIcon><CustomRadio checked={false} testid="solution-permission" /></ListItemIcon>
                  <ListItemText><Typography style={styles.permissionItem}>{configJSON[solutionPermission]}</Typography></ListItemText>
                </ListItem>)}
              </List>}
          </ListItem>)}
        )}
      </List>
    )
  }

  // Customizable Area End

  render () {
    // Customizable Area Start
    const { selectedPermission } = this.state;
    return (
      <React.Fragment>
        <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />

        <Loader loading={this.state.isLoading} />
        
        <Box style={styles.agentContainer}>
          <Box display="flex" justifyContent="space-between">
              <span style={styles.navigation}>
                Settings {">"} User management {">"} Roles
              </span>
              
              <Box style={{ background: '#e6deed', borderRadius: '5px' }}>
                <span style={{ width: '36px', height: '36px', }}>
                  <MoreVert style={{ width: '24px', height: '24px', color: 'black', padding: '5px' }} />
                </span>
              </Box>
          </Box>
          <Box mt="60px" mb="50px">
            <Box display="flex" alignItems="center" gridGap="5px">
              <h1 style={styles.newAgentHeading} data-test-id="roleTestID">{configJSON.newAgentRole}</h1>
              <EditIcon fontSize="small" data-test-id="edit" onClick={() =>{}} />
            </Box>
          </Box>
          <Box style={styles.formBox}>
            <Formik
              initialValues={this.state.initialValues}
              validationSchema={validationSchema}
              onSubmit={this.handleSubmit}
              data-test-id="formikform"
              >
              {({ errors, touched, values, setFieldValue }) => (
                <Form translate={undefined} style={styles.form_main_Box}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <InputLabel style={styles.inputLabel} htmlFor="name">
                        {configJSON.roleNameLabel + " "}<span style={styles.role_span}>*</span>
                      </InputLabel>
                      <Field
                        type="text"
                        data-test-id="agent-role-name"
                        name="roleName"
                        placeholder="Name"
                        id="name"
                        as={TextField}
                        fullWidth
                        variant="outlined"
                        error={touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 15 }}>
                      <InputLabel style={styles.inputLabel} htmlFor="description">
                        {configJSON.description + " "}<span style={styles.role_span}>*</span>
                      </InputLabel>
                      <RichTextEditor
                        data-test-id="agent-text-editor"
                        placeholder="Enter description"
                        rootStyle={{ width:"50%", minHeight: "150px" }}
                        value={values.description}
                        onChange={(value) => setFieldValue('description', value)}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", border: "1px solid #E6DEED", marginTop: 15 }}>
                      <Box>
                        <Typography style={styles.permissionHeading}>{configJSON.agentPermissions}</Typography>
                        <List>
                          {this.state.agentPermissions.map((permission: string) =>
                          <PermissionListItem data-test-id={`permission-list-${permission}`} key={permission} button style={{
                            ...(permission === selectedPermission && styles.activePermission),
                          }} onClick={() => this.handlePermissions(permission)}>
                              <PermissionListItemText disableTypography
                              style={{ ...styles.permission, 
                              ...(permission === selectedPermission && styles.activePermissionText)}}>
                                {permission}
                              </PermissionListItemText>
                          </PermissionListItem>)}
                        </List>
                      </Box>
                      <Box style={styles.detailPermissions}>
                        <Typography style={styles.permissionHeading}>Agent can</Typography>
                        { selectedPermission === configJSON.ticketsPermissions && this.renderTicketPermissions() }
                        { selectedPermission === configJSON.problemPermissions && this.renderProblemPermissions() }
                        { selectedPermission === configJSON.changesPermissions && this.renderChangesPermissions() }
                        { selectedPermission === configJSON.releasesPermissions && this.renderReleasePermissions() }
                        { selectedPermission === configJSON.inventoryPermissions && this.renderInventoryPermission() }
                        { selectedPermission === configJSON.solutionsPermissions && this.renderSolutionPermission() }
                      </Box>
                    </Grid>
                  </Grid>

                  <Box mt='30px' style={styles.buttonBox}>
                    <Button 
                      data-test-id="agentCancelBtn" type="reset" style={styles.agentCancelButton} onClick={() => this.handleNavigation("Rolesandpermissions")}>{configJSON.cancelBTN}</Button>
                    <Button type="submit" 
                      data-test-id="agentSaveBtn" style={styles.agentAddButton} >{configJSON.saveBTN}</Button>
                  </Box>

                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </React.Fragment>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const styles: Record<string, React.CSSProperties> = {
  agentContainer: {
    height: "100%",
    margin: "15px 15px 15px 300px",
    paddingBottom: "125px",
    fontFamily: "Manrope",
  },

  inputLabel: {
    marginBottom: 5,
    fontSize: 12,
    fontWeight: 500,
    color: "#3D405C",
    fontFamily: "Manrope"
  },

  newAgentHeading: {
    color: "#33354D",
    fontSize: "30px",
    margin: "10px 0 0",
    lineHeight: "40px",
    fontWeight: "bold",
  },

  formBox: {
    padding: "24px",
    boxShadow: "rgba(135, 96, 169, 0.16) 0px 8px 32px 0px, rgba(135, 96, 169, 0.13) 0px 4px 8px 0px",
    borderRadius: "8px",
    gap: "24px"
  },

  permissionHeading: {
    marginTop: 10, 
    marginLeft: 15,
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "Manrope",
    lineHeight: "22px"
  },

  permissionsList: {
    display: "flex", 
    flexDirection: "column", 
    alignItems: 'flex-start',
    paddingTop: 0,
    paddingBottom: 0,
  },

  permissionItem: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Manrope",
    color: "#33354D"
  },

  detailPermissions: {
    border: "1px solid rgb(230, 222, 237)",
    margin: "40px 16px 40px 24px",
    width: "100%",
    padding: 8
  },

  role_span: {
    color: "#F22A2A"
  },

  buttonBox: {
    display: "flex",
    justifyContent: "flex-end"
  },

  activePermission: {
    backgroundColor: '#8760A9',
  },

  activePermissionText: {
    color: '#fff'
  },

  agentCancelButton: {
    backgroundColor: "transparent",
    color: "#8760A9",
    padding: "10px 16px",
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "16px",
    borderRadius: "4px",
    letterSpacing: "0em",
    textTransform: "initial",
  },

  agentAddButton: {
    padding: "10px 16px",
    color: "#FFF",
    backgroundColor: "#8760A9",
    fontSize: "16px",
    fontFamily: "Manrope",
    fontWeight: 700,
    letterSpacing: "0em",
    lineHeight: "16px",
    borderRadius: "4px",
    textTransform: "initial",
  } as const,

}
// Customizable Area End

